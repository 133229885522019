import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/common-service/common.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popup-schedule',
  templateUrl: './popup-businessValuation.component.html',
  styleUrls: ['./popup-businessValuation.component.css']
})
export class PopupBusinessValuationComponent implements OnInit {
data = {
  username : '',
  business_name : '',
  email : '',
  phone_number : '',
  type_of_business : '',
  message : '',
  agree : true
}
  constructor(private common: CommonService,public dialogRef: MatDialogRef<PopupBusinessValuationComponent>, @Inject(MAT_DIALOG_DATA) public data1: any) { 
    dialogRef.disableClose = false;
  }

  ngOnInit() {
  }
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(true);
  }
  onNoClick() {
    this.dialogRef.close(true);
  }
  schedule(formdata) {
    console.log(formdata)
    if(formdata.valid){
      if(formdata.value.agree == true){
        this.common.showError("Please agree to the terms and conditions", 4000, false);
        return;
      }
      let data = {
        type : 'enquiry',
        username : formdata.value.username,
        business_name : formdata.value.business_name,
        phone_number : formdata.value.phone_number,
        type_of_business : formdata.value.type_of_business,
        email : formdata.value.email,
        message :  formdata.value.message
      }
      this.common.contact(data).subscribe(
        (result: any) => {
          console.log(result)
          this.data = {
            username : '',
            business_name : '',
            email : '',
            phone_number : '',
            type_of_business : '',
            message : '',
            agree : true
          }
          this.common.showSuccess("Your enquiry request has been sent", 4000, false);
        }, (err: HttpErrorResponse) => {
          this.common.showError("Oops! Something went wrong", 4000, false)
        }
      );
    } else {
      this.common.showError("Please fill the form correctly", 4000, false);
    }
  }
}
