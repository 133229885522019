import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { CommonService } from './common-service/common.service';
import { DataService } from './common-service/data.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PopupScheduleComponent } from './popup-schedule/popup-schedule.component';
import { PopupHelpfulToolComponent } from './popup-helpfulTool/popup-helpfulTool.component';
import { PopupBusinessValuationComponent } from './popup-businessValuation/popup-businessValuation.component'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CarouselPopupComponent } from './carousel-popup/carousel-popup.component';

const dialogMock = {
  close: () => { }
 };
 
@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    PopupScheduleComponent,
    PopupHelpfulToolComponent,
    PopupBusinessValuationComponent,
    CarouselPopupComponent
  ],
  entryComponents: [
    PopupHelpfulToolComponent, 
    PopupBusinessValuationComponent,
    CarouselPopupComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot()
 //   NgHttpLoaderModule.forRoot()
   // MDBBootstrapModule.forRoot()
  ],
  providers: [CommonService,DataService,{ provide: MatDialogRef, useValue: dialogMock },
    { provide: MAT_DIALOG_DATA, useValue: [] },],
  bootstrap: [AppComponent]
})
export class AppModule { }
