import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../common-service/common.service';

@Component({
  selector: 'app-carousel-popup',
  templateUrl: './carousel-popup.component.html',
  styleUrls: ['./carousel-popup.component.css']
})
export class CarouselPopupComponent implements OnInit {

  constructor(private common: CommonService, public dialogRef: MatDialogRef<CarouselPopupComponent>, @Inject(MAT_DIALOG_DATA) public data1: any) {
    dialogRef.disableClose = false;
  }

  ngOnInit() {
  }
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(true);
  }
  onNoClick() {
    this.dialogRef.close(true);
  }
}
