import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PopupScheduleComponent } from './popup-schedule/popup-schedule.component';

const routes: Routes = [
//  { path: '/', redirectTo: '/adminhome/dashboard' ,pathMatch: 'full' },
  { path: '', loadChildren: './frontend/dashboard/dashboard.module#DashboardModule'},
  { path: 'schedule', component: PopupScheduleComponent },
  { path: '**', component: PagenotfoundComponent }   
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
