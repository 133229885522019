import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/common-service/common.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popup-helpfulTool',
  templateUrl: './popup-helpfulTool.component.html',
  styleUrls: ['./popup-helpfulTool.component.css']
})
export class PopupHelpfulToolComponent implements OnInit {
data = {
  username : '',
  business_name : '',
  email : '',
  phone_number : '',
  type_of_business : '',
  message : '',
  agree : false
}
  constructor(private common: CommonService,public dialogRef: MatDialogRef<PopupHelpfulToolComponent>, @Inject(MAT_DIALOG_DATA) public data1: any) { 
    dialogRef.disableClose = false;
  }

  ngOnInit() {
  }
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(true);
  }
  onNoClick() {
    this.dialogRef.close(true);
  }
  schedule(formdata) {
    console.log(formdata)
    if(formdata.valid){
      let data = {
        type : 'enquiry',
        username : formdata.value.username,
        business_name : formdata.value.business_name,
        phone_number : formdata.value.phone_number,
        type_of_business : formdata.vale.type_of_business,
        email : formdata.value.email,
        message :  formdata.value.message
      }
      this.common.contact(data).subscribe(
        (result: any) => {
          console.log(result)
          this.common.showSuccess("Your enquiry request has been sent", 4000, false);
        }, (err: HttpErrorResponse) => {
          this.common.showError("Oops! Something went wrong", 4000, false)
        }
      );
    } else {
      this.common.showError("Please fill the form correctly", 4000, false);
    }
  }
}
