import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl = environment.baseUrl;
  
  constructor(private toastr: ToastrService, private http: HttpClient) { }

  showSuccess(msg, time, bool) {
    this.toastr.success(msg, '', {
      timeOut: time,
      closeButton: bool
    });
  }
  showError(msg, time, bool) {
    this.toastr.error(msg, '', {
      timeOut: time,
      closeButton: bool
    });
  }
  // CONTACT FORM
  contact(data) {
    let headers = new HttpHeaders();
    return this.http.post(`${this.baseUrl}/contact`, data, { headers: headers });
  }
  // SAVE COMMENT FORM
  addComment(data) {
    let headers = new HttpHeaders();
    return this.http.post(`${this.baseUrl}/save_comment`, data, { headers: headers });
  }
  // GET BLOGS
  getBlogs(data) {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/blog_list/`+ data , { headers: headers });
  }
  // GET RECENT BLOGS
  getRecentBlogs() {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/recentBlogs`, { headers: headers });
  }
  // GET BLOG DETAIL
  getBlogDetail(data) {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/blog_detail/`+ data, { headers: headers });
  }
  // GET ALL COMMENTS
  getComments(data) {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/getAllComments/`+ data, { headers: headers });
  }
  // GET STATIC PAGES
  getPageDetail(data) {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/staticpages/`+ data, { headers: headers });
  }
  // GET STATIC PAGES
  getPropertyListings() {
    let headers = new HttpHeaders();
    return this.http.get(`${this.baseUrl}/abc`, { headers: headers });
  }
}
